import {Dispatch} from "@reduxjs/toolkit";
import {Market} from "../../domain/tradetarget/TradeTarget";
import PriceWebAdapter from "../../adapter/price/PriceWebAdapter";


export default class PriceService {
    private readonly priceWebAdapter = new PriceWebAdapter()
    private readonly dispatch: Dispatch;

    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch
    }



    async invokeCollectPricesByMarket(market: Market, token: String): Promise<void> {
        await this.priceWebAdapter.invokeCollectPricesByMarket(market, token)
    }

    async invokeCollectPricesByTarget(targetIdentifier: String, token: String): Promise<void> {
        await this.priceWebAdapter.invokeCollectPricesByTarget(targetIdentifier, token)
    }

    async invokeCollectPricesByTargetAndUnit(targetIdentifier: String, unit: String, token: String): Promise<void> {
        await this.priceWebAdapter.invokeCollectPricesByTargetAndUnit(targetIdentifier, unit, token)
    }

}