import {Auth, Token, UserRole} from "../../domain/auth/Auth";
import axios from "axios";
import ServerProperty from "../../global/property/ServerProperty";
import handleException from "../../global/exception/AxiosExceptionHandler";
import {OffsetDateTime} from "../../global/type/OffsetDateTime";

interface AuthResponse {
    nickname: String,
    accessToken: String,
    accessTokenExpirationTime: String,
    refreshToken: String,
    refreshTokenExpirationTime: String,
    roles: String[]
}

export default class AuthWebAdapter {

    async login(id: String, pw: String): Promise<Auth | null> {
        try {
            if (id === undefined) return null
            const body = {
                account: id,
                password: pw
            }
            const res = await axios.post(`${ServerProperty.BASE_URL}/composite/auth/login`, body)
            const response =  res.data as AuthResponse;

            return new Auth(
                response.nickname,
                new Token(response.accessToken, OffsetDateTime.parseOffsetDateTime(response.accessTokenExpirationTime)!!),
                new Token(response.refreshToken, OffsetDateTime.parseOffsetDateTime(response.refreshTokenExpirationTime)!!),
                response.roles as UserRole[]
            )
        } catch (err) {
            handleException(err)
            return null
        }
    }

    async refresh(refreshToken: String): Promise<Token | null> {
        try {
            const header = {
                'Authorization': `Bearer ${refreshToken}`
            }
            const res = await axios.post(`${ServerProperty.BASE_URL}/composite/auth/refresh`, null, {headers: header})

            return res.data as Token;
        } catch (err) {
            handleException(err)
            return null
        }
    }
}
