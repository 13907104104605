import Header from "../global/component/Header";
import React, {useEffect, useState} from "react"
import {Body, Screen} from "./Screen";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import TaskGroup from "./components/task/TaskGroup";
import TaskExecute, {ParamType} from "./components/task/TaskExecute";
import {ReducerType} from "../global/config/RootReducer";
import {AuthState} from "../adapter/auth/AuthStorageAdapter";
import {Market} from "../domain/tradetarget/TradeTarget";
import {TradeTargetState} from "../adapter/tradetarget/TradeTargetStorageAdapter";
import {UnitState} from "../adapter/unit/UnitStorageAdapter";
import PriceService from "../application/price/PriceService";
import TradeTargetService from "../application/tradetargets/TradeTargetService";
import {Auth} from "../domain/auth/Auth";
import AuthService from "../application/auth/AuthService";


const StyledBody = styled(Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
`


const TaskScreen: React.FC = () => {
    const authState = useSelector<ReducerType, AuthState | null>(state => state.Auth)
    const unitState = useSelector<ReducerType, UnitState[]>(state => state.Unit)
    const targetState = useSelector<ReducerType, TradeTargetState[]>(state => state.TradeTarget)

    const [targetOfCollectTargetByPriceOnBlockchain, setTargetOfCollectTargetByPriceOnBlockchain] = useState<String>("")
    const [targetOfCollectTargetByPriceAndUnitOnBlockchain, setTargetOfCollectTargetByPriceAndUnitOnBlockchain] = useState<String>("")
    const [unitOfCollectTargetByPriceAndUnitOnBlockchain, setUnitOfCollectTargetByPriceAndUnitOnBlockchain] = useState<String>("")

    const [targetOfCollectTargetByPriceOnStock, setTargetOfCollectTargetByPriceOnStock] = useState<String>("")
    const [targetOfCollectTargetByPriceAndUnitOnStock, setTargetOfCollectTargetByPriceAndUnitOnStock] = useState<String>("")
    const [unitOfCollectTargetByPriceAndUnitOnStock, setUnitOfCollectTargetByPriceAndUnitOnStock] = useState<String>("")

    const dispatch = useDispatch();

    const authService = new AuthService(dispatch)
    const tradeTargetService = new TradeTargetService(dispatch)
    const priceService = new PriceService(dispatch)

    useEffect(() => {

    }, []);

    return (
        <Screen>
            <Header/>
            <StyledBody>
                <TaskGroup title={"Data Collecting - Coin"}>
                    <TaskExecute
                        title={"Collect Target"}
                        onSubmit={async () => {
                            if (authState === null) return
                            const token = await authService.getRefreshedToken(Auth.of(authState))
                            await tradeTargetService.invokeCollectTradeTargets(
                                Market.BLOCKCHAIN,
                                token.value
                            )
                        }}
                    />
                    <TaskExecute
                        title={"Collect Price"}
                        onSubmit={async () => {
                            if (authState === null) return
                            const token = await authService.getRefreshedToken(Auth.of(authState))
                            await priceService.invokeCollectPricesByMarket(
                                Market.BLOCKCHAIN,
                                token.value
                            )
                        }}
                    />
                    <TaskExecute
                        title={"Collect Price By Target"}
                        params={
                            [
                                {
                                    key: "Target",
                                    type: ParamType.TARGET,
                                    values: targetState
                                        .filter((target) => target.market === Market.BLOCKCHAIN)
                                        .sort((a, b) => a.identifier.localeCompare(b.identifier.toString()))
                                        .map((target) => {
                                                return {
                                                    keyword: target.identifier.concat(" - ", target.name.toString()),
                                                    value: target.identifier
                                                }
                                            }
                                        ),
                                    selected: targetOfCollectTargetByPriceOnBlockchain,
                                    onSelected: (value) => setTargetOfCollectTargetByPriceOnBlockchain(value)
                                },
                            ]
                        }
                        onSubmit={async () => {
                            if (authState === null) return
                            const token = await authService.getRefreshedToken(Auth.of(authState))
                            await priceService.invokeCollectPricesByTarget(
                                targetOfCollectTargetByPriceOnBlockchain,
                                token.value
                            )
                        }}
                    />
                    <TaskExecute
                        title={"Collect Price By Target And Unit"}
                        params={
                            [
                                {
                                    key: "Target",
                                    type: ParamType.TARGET,
                                    values: targetState
                                        .filter((target) => target.market === Market.BLOCKCHAIN)
                                        .sort((a, b) => a.identifier.localeCompare(b.identifier.toString()))
                                        .map((target) => {
                                                return {
                                                    keyword: target.identifier.concat(" - ", target.name.toString()),
                                                    value: target.identifier
                                                }
                                            }
                                        ),
                                    selected: targetOfCollectTargetByPriceAndUnitOnBlockchain,
                                    onSelected: (value) => setTargetOfCollectTargetByPriceAndUnitOnBlockchain(value)
                                },
                                {
                                    key: "Unit",
                                    type: ParamType.UNIT,
                                    values: unitState.map((unit) => {
                                            return {
                                                keyword: unit.unit,
                                                value: unit.unit
                                            }
                                        }
                                    ),
                                    selected: unitOfCollectTargetByPriceAndUnitOnBlockchain,
                                    onSelected: (value) => setUnitOfCollectTargetByPriceAndUnitOnBlockchain(value)
                                },
                            ]
                        }
                        onSubmit={async () => {
                            if (authState === null) return
                            const token = await authService.getRefreshedToken(Auth.of(authState))
                            await priceService.invokeCollectPricesByTargetAndUnit(
                                targetOfCollectTargetByPriceAndUnitOnBlockchain,
                                unitOfCollectTargetByPriceAndUnitOnBlockchain,
                                token.value
                            )
                        }}
                    />
                </TaskGroup>

                <TaskGroup title={"Data Collecting - Stock"}>
                    <TaskExecute
                        title={"Collect Target"}
                        onSubmit={async () => {
                            if (authState === null) return
                            const token = await authService.getRefreshedToken(Auth.of(authState))
                            await tradeTargetService.invokeCollectTradeTargets(
                                Market.STOCK,
                                token.value
                            )
                        }}
                    />
                    <TaskExecute
                        title={"Collect Price"}
                        onSubmit={async () => {
                            if (authState === null) return
                            const token = await authService.getRefreshedToken(Auth.of(authState))
                            await priceService.invokeCollectPricesByMarket(
                                Market.STOCK,
                                token.value
                            )
                        }
                        }
                    />
                    <TaskExecute
                        title={"Collect Price By Target"}
                        params={
                            [
                                {
                                    key: "Target",
                                    type: ParamType.TARGET,
                                    values: targetState
                                        .filter((target) => target.market === Market.STOCK)
                                        .sort((a, b) => a.identifier.localeCompare(b.identifier.toString()))
                                        .map((target) => {
                                                return {
                                                    keyword: target.identifier.concat(" - ", target.name.toString()),
                                                    value: target.identifier
                                                }
                                            }
                                        ),
                                    selected: targetOfCollectTargetByPriceOnStock,
                                    onSelected: (value) => setTargetOfCollectTargetByPriceOnStock(value)
                                },
                            ]
                        }
                        onSubmit={async () => {
                            if (authState === null) return
                            const token = await authService.getRefreshedToken(Auth.of(authState))
                            await priceService.invokeCollectPricesByTarget(
                                targetOfCollectTargetByPriceOnStock,
                                token.value
                            )
                        }}
                    />
                    <TaskExecute
                        title={"Collect Price By Target And Unit"}
                        params={
                            [
                                {
                                    key: "Target",
                                    type: ParamType.TARGET,
                                    values: targetState
                                        .filter((target) => target.market === Market.STOCK)
                                        .sort((a, b) => a.identifier.localeCompare(b.identifier.toString()))
                                        .map((target) => {
                                                return {
                                                    keyword: target.identifier.concat(" - ", target.name.toString()),
                                                    value: target.identifier
                                                }
                                            }
                                        ),
                                    selected: targetOfCollectTargetByPriceAndUnitOnStock,
                                    onSelected: (value) => setTargetOfCollectTargetByPriceAndUnitOnStock(value)
                                },
                                {
                                    key: "Unit",
                                    type: ParamType.UNIT,
                                    values: unitState.map((unit) => {
                                            return {
                                                keyword: unit.unit,
                                                value: unit.unit
                                            }
                                        }
                                    ),
                                    selected: unitOfCollectTargetByPriceAndUnitOnStock,
                                    onSelected: (value) => setUnitOfCollectTargetByPriceAndUnitOnStock(value)
                                },
                            ]
                        }
                        onSubmit={async () => {
                            if (authState === null) return
                            const token = await authService.getRefreshedToken(Auth.of(authState))
                            await priceService.invokeCollectPricesByTargetAndUnit(
                                targetOfCollectTargetByPriceAndUnitOnStock,
                                unitOfCollectTargetByPriceAndUnitOnStock,
                                token.value
                            )
                        }}
                    />
                </TaskGroup>
            </StyledBody>
        </Screen>
    )
}

export default TaskScreen;