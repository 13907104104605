import Header from "../global/component/Header";
import {Screen, Body} from "./Screen";

import { MdOutlineWarningAmber } from "react-icons/md";
import React from "react"
import styled from "styled-components";

const StyledBody = styled(Body)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  
  & #invalid-path-icon {
    font-size: 50px;
  }
`


const InvalidScreen: React.FC = () => {
    return (
        <Screen>
            <Header/>
            <StyledBody>
                <MdOutlineWarningAmber id={"invalid-path-icon"} />
                Invalid Path!
            </StyledBody>
        </Screen>
    )
}

export default InvalidScreen;