import {OffsetDateTime} from "../../global/type/OffsetDateTime";
import internal from "stream";

export enum Market {
    STOCK="STOCK",
    BLOCKCHAIN="BLOCKCHAIN",
}

export enum TradeTargetStatus {
    LISTED="LISTED",
    CAUTION="CAUTION",
    WARNING="WARNING",
    TRADING_HALTED="TRADING_HALTED",
    DELISTED="DELISTED",
}

export class TradeTarget {
    identifier: String
    market: Market
    name: String
    status: TradeTargetStatus

    constructor(identifier: String, market: Market, name: String, status: TradeTargetStatus) {
        this.identifier = identifier
        this.market = market
        this.name = name
        this.status = status
    }
}