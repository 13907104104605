import Header from "../global/component/Header";
import React, {useState} from "react"
import {Body, Screen} from "./Screen";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ReducerType} from "../global/config/RootReducer";
import {Auth} from "../domain/auth/Auth";
import AuthService from "../application/auth/AuthService";
import {AuthState} from "../adapter/auth/AuthStorageAdapter";

const StyledBody = styled(Body)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

const LoginForm = styled.form`
  --padding: 20px;
  width: calc(300px - var(--padding) * 2);
  height: calc(300px - var(--padding) * 2);
  
  background-color: var(--background-020);

  padding: var(--padding);
  border: solid 1px var(--background-030);
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

const LoginTitle = styled.p`
  margin: 0;
`

const LoginInput = styled.input`
  --padding-width: 10px;
  --padding-height: 10px;

  height: calc(56px - var(--padding-height) * 2 - 1px);
  width: calc(250px - var(--padding-width) * 2);

  font-size: 1rem;

  border: none;
  border-bottom: solid 1px var(--background-030);

  padding: 0 var(--padding-width);

  color: var(--foreground-020);
  background-color: var(--background-020);

  &:focus {
    outline: none;
  }
`

const LoginButton = styled.button`
  font-size: 1rem;

  border: none;
  border-radius: 15px;

  width: 250px;
  height: 30px;

  color: var(--foreground-020);
  background-color: var(--background-020);

  &:hover, &:active {
    cursor: pointer;
    background-color: var(--background-030);
    transition: 0.5s;
  }
`


const LoginScreen: React.FC = () => {
    const auth = useSelector<ReducerType, AuthState | null>(state => state.Auth)

    const [id, setId] = useState<String>("");
    const [pw, setPw] = useState<String>("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const service = new AuthService(dispatch)

    async function tryLogin() {
        try {
            // TODO dependency inject

            await service.login(id, pw)
            navigate("/")
        } catch (err: any) {
            window.alert(err.toString())
        }
    }

    return (
        <Screen>
            <Header/>
            <StyledBody>
                <LoginForm
                    onSubmit={(e) => {
                        e.preventDefault()
                        tryLogin().then()
                    }}
                >
                    <LoginTitle>
                        Trade Pilot
                    </LoginTitle>
                    <LoginInput
                        placeholder={"ID"}
                        value={id.toString()}
                        onChange={(e) => setId(e.target.value)}
                    />
                    <LoginInput
                        type={"password"}
                        placeholder={"PW"}
                        value={pw.toString()}
                        onChange={(e) => setPw(e.target.value)}
                    />
                    <LoginButton>Login</LoginButton>
                </LoginForm>
            </StyledBody>
        </Screen>
    )
}

export default LoginScreen;