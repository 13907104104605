import {Dispatch} from "@reduxjs/toolkit";
import {Token} from "../../domain/auth/Auth";
import TradeTargetWebAdapter from "../../adapter/tradetarget/TradeTargetWebAdapter";
import {updateTradeTargets} from "../../adapter/tradetarget/TradeTargetStorageAdapter";
import {Market} from "../../domain/tradetarget/TradeTarget";


export default class TradeTargetService {
    private readonly tradeTargetWebAdapter = new TradeTargetWebAdapter()
    private readonly dispatch: Dispatch;

    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch
    }

    async collect(token: Token): Promise<void> {
        const newTargets = await this.tradeTargetWebAdapter.collect(token.value)
        this.dispatch(updateTradeTargets(newTargets))
    }

    async invokeCollectTradeTargets(market: Market, token: String): Promise<void> {
        await this.tradeTargetWebAdapter.invokeCollectTradeTargets(market, token)
    }

}