import {AxiosError} from "axios";
import {OffsetDateTime} from "../type/OffsetDateTime";
import {BusinessException, ClientException, ServerException} from "./Exception";

type ErrorResponse = {
    status: number,
    path: String,
    message: String,
    timestamp: OffsetDateTime
}

export default function handleException(err: any) {
    if(err instanceof AxiosError) {
        if(err.response) {
            const response =  err.response.data as ErrorResponse

            if(response.status >= 400 && response.status <= 499) {
                // request exception
                throw new BusinessException(response.status, response.message)
            } else {
                // server exception
                throw new ServerException(response.status, response.message)
            }
        } else {
            // client exception
            throw new ClientException(err.status ?? 500, err.message)
        }
    } else {
        throw new ClientException(err.status ?? 500, err.message)
    }
}