import Header from "../global/component/Header";
import React from "react";

function HomeScreen() {
    return (
        <div>
            <Header/>
            Home
        </div>
    )
}

export default HomeScreen;