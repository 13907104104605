export class OffsetDateTime {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    second: number;
    nanoseconds: number;
    offsetHours: number;
    offsetMinutes: number;

    constructor(year: number, month: number, day: number, hour: number, minute: number, second: number, nanoseconds: number, offsetHours: number, offsetMinutes: number) {
        this.year = year;
        this.month = month;
        this.day = day;
        this.hour = hour;
        this.minute = minute;
        this.second = second;
        this.nanoseconds = nanoseconds
        this.offsetHours = offsetHours;
        this.offsetMinutes = offsetMinutes;
    }

    static parseOffsetDateTime(dateTimeString: String): OffsetDateTime | null {
        const match = dateTimeString.match( /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d+))?([+-])(\d{2}):(\d{2})/);
        if (!match) return null;

        const [, yearStr, monthStr, dayStr, hourStr, minuteStr, secondStr, nanoStr, sign, offsetHoursStr, offsetMinutesStr] = match;
        const year = parseInt(yearStr, 10);
        const month = parseInt(monthStr, 10);
        const day = parseInt(dayStr, 10);
        const hour = parseInt(hourStr, 10);
        const minute = parseInt(minuteStr, 10);
        const second = parseInt(secondStr, 10);
        const nanoseconds = nanoStr ? parseInt(nanoStr, 10) : 0;

        // Adjust offset hours and minutes according to the sign
        const offsetSign = sign === '-' ? -1 : 1;
        const offsetHours = parseInt(offsetHoursStr, 10) * offsetSign;
        const offsetMinutes = parseInt(offsetMinutesStr, 10) * offsetSign;

        return new OffsetDateTime(year, month, day, hour, minute, second, nanoseconds, offsetHours, offsetMinutes);

    }

    toString(): string {
        const padZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`);
        const offsetSign = this.offsetHours >= 0 ? '+' : '-';
        const offsetHours = Math.abs(this.offsetHours);
        const offsetMinutes = Math.abs(this.offsetMinutes);

        // Format seconds with optional nanoseconds
        const secondsStr = this.nanoseconds
            ? `${padZero(this.second)}.${this.nanoseconds.toString().padEnd(9, '0')}`
            : padZero(this.second);

        return `${this.year}-${padZero(this.month)}-${padZero(this.day)}T${padZero(this.hour)}:${padZero(this.minute)}:${secondsStr}${offsetSign}${padZero(offsetHours)}:${padZero(offsetMinutes)}`;
    }


    isEqual(other: OffsetDateTime): boolean {
        return (
            this.year === other.year &&
            this.month === other.month &&
            this.day === other.day &&
            this.hour === other.hour &&
            this.minute === other.minute &&
            this.second === other.second &&
            this.nanoseconds === other.nanoseconds &&
            this.offsetHours === other.offsetHours &&
            this.offsetMinutes === other.offsetMinutes
        );
    }

    static now(): OffsetDateTime {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1; // 월은 0부터 시작하므로 +1 해줍니다.
        const day = now.getDate();
        const hour = now.getHours();
        const minute = now.getMinutes();
        const second = now.getSeconds();
        const nanoseconds = now.getMilliseconds() * 1000000; // 밀리초를 나노초로 변환합니다.
        const offsetHours = -(now.getTimezoneOffset() / 60); // 타임존 오프셋을 구해서 음수로 변환합니다.
        const offsetMinutes = -(now.getTimezoneOffset() % 60); // 타임존 오프셋의 분 단위를 구합니다.

        return new OffsetDateTime(year, month, day, hour, minute, second, nanoseconds, offsetHours, offsetMinutes);
    }

    isBefore(other: OffsetDateTime): boolean {
        if (this.year < other.year) return true;
        if (this.year > other.year) return false;
        if (this.month < other.month) return true;
        if (this.month > other.month) return false;
        if (this.day < other.day) return true;
        if (this.day > other.day) return false;
        if (this.hour < other.hour) return true;
        if (this.hour > other.hour) return false;
        if (this.minute < other.minute) return true;
        if (this.minute > other.minute) return false;
        if (this.second < other.second) return true;
        if (this.second > other.second) return false;
        if (this.nanoseconds < other.nanoseconds) return true;
        if (this.offsetHours < other.offsetHours) return true;
        if (this.offsetHours > other.offsetHours) return false;
        if (this.offsetMinutes < other.offsetMinutes) return true;
        return false;
    }

    isAfter(other: OffsetDateTime): boolean {
        return !this.isBefore(other) && !this.isEqual(other);
    }
}