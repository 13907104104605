import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {UnitType} from "../../domain/unit/Unit";

export interface UnitState {
    value: Number,
    unit: String,
    isBaseUnit: Boolean
}

export const DefaultUnits: UnitState[] = []

export const UnitSlice = createSlice({
    name: "Unit",
    initialState: DefaultUnits,
    reducers: {
        updateUnit(state, action: PayloadAction<UnitState[]>) {
            return action.payload;
        }
    }
})


export const { updateUnit } = UnitSlice.actions;
export default UnitSlice.reducer;