import {OffsetDateTime} from "../../global/type/OffsetDateTime";
import {AuthState} from "../../adapter/auth/AuthStorageAdapter";

export enum UserRole {
    GUEST="GUEST",
    MONITOR="MONITOR",
    USER="USER",
    ADMIN="ADMIN",
}

export class Auth {
    readonly nickname: String
    readonly access: Token
    readonly refresh: Token
    readonly roles: UserRole[]

    constructor(nickname: String, access: Token, refresh: Token, roles: UserRole[]) {
        this.nickname = nickname
        this.access = access
        this.refresh = refresh
        this.roles = roles
    }

    static of(state: AuthState): Auth {
        return new Auth(
            state.nickname,
            new Token(state.accessToken, OffsetDateTime.parseOffsetDateTime(state.accessTokenExpirationDate)!!),
            new Token(state.refreshToken, OffsetDateTime.parseOffsetDateTime(state.refreshTokenExpirationDate)!!),
            state.roles
        )
    }

    toState(): AuthState {
        return {
            nickname: this.nickname,
            accessToken: this.access.value,
            accessTokenExpirationDate: this.access.expirationDate?.toString(),
            refreshToken: this.refresh.value,
            refreshTokenExpirationDate: this.refresh.expirationDate?.toString(),
            roles: this.roles
        }
    }

    updateAccess(token: Token): Auth {
        return new Auth(this.nickname, token, this.refresh, this.roles)
    }
}

export class Token {
    readonly value: String
    readonly expirationDate :OffsetDateTime

    constructor(value: String, expirationDate: OffsetDateTime) {
        this.value = value
        this.expirationDate = expirationDate
    }
}