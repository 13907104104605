import {Auth, Token} from "../../domain/auth/Auth";
import {OffsetDateTime} from "../../global/type/OffsetDateTime";
import AuthWebAdapter from "../../adapter/auth/AuthWebAdapter";
import {Dispatch} from "@reduxjs/toolkit";
import {clearAuth, updateAuth} from "../../adapter/auth/AuthStorageAdapter";
import UnauthorizedException from "../../domain/auth/exceptions/UnauthorizedException";


export default class AuthService {
    private readonly authWebAdapter = new AuthWebAdapter()
    private readonly dispatch: Dispatch;

    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch
    }

    async getRefreshedToken(auth: Auth): Promise<Token> {
        if(this.isExpire(auth.access)) {
            const refreshedAuth = await this.refresh(auth)
            return refreshedAuth.access
        } else {
            return auth.access
        }
    }

    isExpire(token: Token | null | undefined): Boolean {
        if (token?.expirationDate === undefined) return true
        return token.expirationDate.isBefore(OffsetDateTime.now())
    }

    async login(id: String, pw: String): Promise<Auth | null> {
        const auth = await this.authWebAdapter.login(id, pw)

        if (auth != null) this.dispatch(updateAuth(auth.toState()))
        return auth
    }

    async refresh(auth: Auth): Promise<Auth> {
        const token = await this.authWebAdapter.refresh(auth.refresh.value)

        if(token === null) throw new UnauthorizedException()

        const updatedAuth = auth.updateAccess(token)
        this.dispatch(updateAuth(updatedAuth.toState()))
        return updatedAuth
    }

    logout(): void {
        this.dispatch(clearAuth())
    }
}