import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {UnitType} from "../../domain/unit/Unit";
import {DefaultUnits, UnitSlice} from "../unit/UnitStorageAdapter";
import {UserRole} from "../../domain/auth/Auth";
import {OffsetDateTime} from "../../global/type/OffsetDateTime";

export interface TradeTargetState {
    identifier: String,
    market: String,
    name: String,
    status: String,
}

export const DefaultTradeTargets: TradeTargetState[] = []

export const TradeTargetSlice = createSlice({
    name: "TradeTarget",
    initialState: DefaultTradeTargets,
    reducers: {
        updateTradeTargets(state, action: PayloadAction<TradeTargetState[]>) {
            return action.payload;
        }
    }
})


export const { updateTradeTargets } = TradeTargetSlice.actions;
export default TradeTargetSlice.reducer;