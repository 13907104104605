import {ClientException} from "../../../global/exception/Exception";
import {HttpStatusCode} from "axios";

export default class UnauthorizedException extends ClientException {

    constructor() {
        super(HttpStatusCode.Unauthorized, "로그인이 필요합니다.");
    }

    toString(): String {
        return `[${this.status}] ${this.cause}`
    }
}