import AuthWebAdapter from "../../adapter/auth/AuthWebAdapter";
import {Dispatch} from "@reduxjs/toolkit";
import UnitWebAdapter from "../../adapter/unit/UnitWebAdapter";
import {UnitType} from "../../domain/unit/Unit";
import {Token} from "../../domain/auth/Auth";
import {updateUnit} from "../../adapter/unit/UnitStorageAdapter";


export default class UnitService {
    private readonly unitWebAdapter = new UnitWebAdapter()
    private readonly dispatch: Dispatch;

    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch
    }

    async collect(token: Token): Promise<void> {
        const newUnits = await this.unitWebAdapter.collect(token.value)
        this.dispatch(updateUnit(newUnits))
    }

}