import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import {Provider} from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import './global/style/Font.css';
import './global/style/Color.css';
import './global/style/Index.css';

import App from './App';
import store from "./global/config/Store";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const persistor = persistStore(store);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>
);

reportWebVitals();
