import {persistReducer} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import {combineReducers} from "@reduxjs/toolkit";
import Auth from "../../adapter/auth/AuthStorageAdapter";
import Unit from "../../adapter/unit/UnitStorageAdapter";
import TradeTarget from "../../adapter/tradetarget/TradeTargetStorageAdapter";

const persistConfig = {
    key: "root",
    storage: storageSession,

    // whitelist: ["auth"], // storage에 저장하는 reducer
    // blacklist: [] // 제외하는 reducer
};

const reducer = combineReducers({
    Auth,
    Unit,
    TradeTarget
});

export type ReducerType = ReturnType<typeof reducer>;
export default persistReducer(persistConfig, reducer);