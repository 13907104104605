import styled from "styled-components";
import React from "react";

const TaskExecuteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  margin: 10px;
  padding: 10px;
  
  border: solid 1px var(--background-030);
`

const TaskExecuteBtn = styled.button`
  height: 2rem;

  border: solid 1px var(--background-030);
  border-radius: 0.5rem;
  
  font-size: 1.2rem;
  font-weight: bold;
  
  color: var(--foreground-020);
  background-color: var(--background-020);
  
  &:hover, &:active {
    cursor: pointer;
    background-color: var(--background-030);
    transition: 0.5s;
  }
`

const TaskExecuteInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`

const TaskExecuteInput = styled.select`
  flex: 1;
  min-width: 230px;
  height: 2rem;
  
  padding: 0 1rem;

  border: solid 1px var(--background-030);
  border-radius: 0.5rem;

  color: var(--foreground-020);
  background-color: var(--background-020);
`

export enum ParamType {
    TARGET,
    UNIT
}

interface TaskExecuteParamValue {
    keyword: String,
    value: String
}

interface TaskExecuteParamProps {
    key: String,
    type: ParamType,
    values: TaskExecuteParamValue[],
    selected: String,
    onSelected(value: String): void
}

interface TaskExecuteProps {
    title: String
    onSubmit?(): void,
    params?: TaskExecuteParamProps[],
}

const TaskExecute: React.FC<TaskExecuteProps> = (props) => {
    return (
        <TaskExecuteContainer>
            <TaskExecuteInputContainer>
                {
                    props.params?.map((param) => {
                        switch (param.type) {
                            case ParamType.TARGET:
                            case ParamType.UNIT:
                                return (
                                    <TaskExecuteInput
                                        key={param.key.toString()}
                                        value={param.selected.toString()}
                                        onChange={(event) => param.onSelected(event.target.value)}
                                    >
                                        {param.values.map((value) => (
                                            <option key={value.keyword.toString()} value={value.value.toString()}>{value.keyword}</option>
                                        ))}
                                    </TaskExecuteInput>
                                );
                            default:
                                return null; // 처리되지 않은 경우에 대한 기본 반환값
                        }
                    })
                }
            </TaskExecuteInputContainer>
            <TaskExecuteBtn
                onClick={props.onSubmit}
            >
                {props.title}
            </TaskExecuteBtn>
        </TaskExecuteContainer>
    );
}

export default TaskExecute;