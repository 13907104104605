import axios from "axios";
import ServerProperty from "../../global/property/ServerProperty";
import handleException from "../../global/exception/AxiosExceptionHandler";
import {UnitType} from "../../domain/unit/Unit";

interface UnitResponse {
    value: Number
    unit: String
    isBaseUnit: Boolean
}

export default class UnitWebAdapter {
    async collect(token: String): Promise<UnitType[]> {
        try {
            console.log("unit web adapter collect called")
            const header = {
                Authorization: `Bearer ${token}`
            }

            const res = await axios.get(`${ServerProperty.BASE_URL}/data/units`, {headers: header})
            const resDatas = res.data as UnitResponse[];

            console.log("unit web adapter collect called. size: " + resDatas.length)
            return resDatas.map((resData) => {
                return new UnitType(resData.value, resData.unit, resData.isBaseUnit)
            })
        } catch (err) {
            handleException(err)
            return []
        }
    }



}