import styled from "styled-components";

export const Screen = styled.div`
  position: absolute;
  top: 0;
  height: calc(100vh - var(--header-height));
`

export const Body = styled.div`
  width: 100vw;
  height: calc(100vh - var(--header-height));
  
  font-size: 30px;
  font-weight: bold;
`