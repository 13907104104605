import {OffsetDateTime} from "../../global/type/OffsetDateTime";
import internal from "stream";
import {UnitState} from "../../adapter/unit/UnitStorageAdapter";



export class UnitType {
    value: Number
    unit: String
    isBaseUnit: Boolean

    constructor(value: Number, unit: String, isBaseUnit: Boolean) {
        this.value = value
        this.unit = unit
        this.isBaseUnit = isBaseUnit
    }

    toState(): UnitState {
        return {
            value: this.value,
            unit: this.unit,
            isBaseUnit: this.isBaseUnit
        }
    }

    static of(state: UnitState): UnitType {
        return new UnitType(state.value, state.unit, state.isBaseUnit)
    }
}