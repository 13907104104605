import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import HomeScreen from './screen/HomeScreen';
import LoginScreen from "./screen/LoginScreen";
import InvalidScreen from "./screen/InvalidScreen";
import TaskScreen from "./screen/TaskScreen";
import {useDispatch, useSelector} from "react-redux";
import {ReducerType} from "./global/config/RootReducer";
import {Auth} from "./domain/auth/Auth";
import UnitService from "./application/unit/UnitService";
import AuthService from "./application/auth/AuthService";
import {AuthState} from "./adapter/auth/AuthStorageAdapter";
import TradeTargetService from "./application/tradetargets/TradeTargetService";

function App() {
    const authState = useSelector<ReducerType, AuthState | null>(state => state.Auth)
    const dispatch = useDispatch();

    useEffect(() => {
        if(authState === null) return

        async function init(auth: Auth) {
            const authService = new AuthService(dispatch)

            const token = await authService.getRefreshedToken(auth)

            const unitService= new UnitService(dispatch);
            const tradeTargetService = new TradeTargetService(dispatch)

            await unitService.collect(token)
            await tradeTargetService.collect(token)
        }

        init(Auth.of(authState)).then()

    }, [authState, dispatch]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<HomeScreen/>}/>
                <Route path={'/login'} element={<LoginScreen/>}/>
                <Route path={'/task'} element={<TaskScreen/>}/>
                <Route path={'/*'} element={<InvalidScreen/>}/>
            </Routes>
        </BrowserRouter>
    );

}

export default App;
