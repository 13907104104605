

export class BusinessException extends Error {
    private readonly status: Number;

    constructor(status: Number, cause: String) {
        super();
        this.status = status
        this.cause = cause
    }

    toString(): String {
        return `[${this.status}] ${this.cause}`
    }
}

export class ClientException extends Error {

    protected readonly status: Number;

    constructor(status: Number, cause: String) {
        super();
        this.status = status
        this.cause = cause
    }

    toString(): String {
        return `[${this.status}] ${this.cause}`
    }
}

export class ServerException extends Error {

    protected  readonly status: Number;

    constructor(status: Number, cause: String) {
        super();
        this.status = status
        this.cause = cause
    }

    toString(): String {
        return `[${this.status}] ${this.cause}`
    }
}