import styled from "styled-components";
import React, {useState} from "react";
import {BiSolidDownArrow, BiSolidRightArrow} from "react-icons/bi";

const TaskGroupContainer = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  flex-direction: column;
  align-items: center;
`

interface GroupHeaderProps {
    $isFold: boolean
}

const TaskGroupHeader = styled.div<GroupHeaderProps>`
  width: calc(100% - 40px);
  height: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  
  font-size: 1.5rem;

  margin: 10px;
  padding: 10px;

  border: solid 1px var(--background-030);
  border-radius: 5px;

  ${props => !props.$isFold ? 'margin-bottom: 0px;' : ''}
  ${props => !props.$isFold ? 'border-bottom-left-radius: 0px;' : ''}
  ${props => !props.$isFold ? 'border-bottom-right-radius: 0px;' : ''}
}
`

const TaskGroupBody = styled.div`
  width: calc(100% - 40px);
  margin: 0 10px;
  padding: 10px;
  
  font-size: 1rem;
  
  border: solid 1px var(--background-030);
  border-top: none;
`

interface GroupProps {
    title: String,
    children: React.ReactNode
}

const TaskGroup: React.FC<GroupProps> = (props) => {
    const [isFold, setIsFold] = useState<boolean>(true);

    function onHeaderClick() {
        setIsFold(!isFold)
    }

    if(isFold) {
        return (
            <TaskGroupHeader
                $isFold={isFold}
                onClick={onHeaderClick}
            >
                <BiSolidRightArrow/>
                {props.title}
            </TaskGroupHeader>
        )
    } else {
        return (
            <TaskGroupContainer>
                <TaskGroupHeader
                    $isFold={isFold}
                    onClick={onHeaderClick}
                >
                    <BiSolidDownArrow/>
                    {props.title}
                </TaskGroupHeader>
                <TaskGroupBody>
                    {props.children}
                </TaskGroupBody>
            </TaskGroupContainer>
        )
    }
}

export default TaskGroup