import axios from "axios";
import ServerProperty from "../../global/property/ServerProperty";
import handleException from "../../global/exception/AxiosExceptionHandler";
import {Market} from "../../domain/tradetarget/TradeTarget";

export default class PriceWebAdapter {


    async invokeCollectPricesByMarket(market: Market, token: String): Promise<void> {
        try {
            console.log("price web adapter invoke collect price by market called. market: " + market)
            const header = {
                Authorization: `Bearer ${token}`
            }

            const res = await axios.post(`${ServerProperty.BASE_URL}/data/price/markets/${market.toString()}/collect`, null,{headers: header})

            console.log("price web adapter invoke collect price by market called. status: " + res.status)

            return
        } catch (err) {
            handleException(err)
            return
        }
    }

    async invokeCollectPricesByTarget(targetIdentifier: String, token: String): Promise<void> {
        try {
            console.log("price web adapter invoke collect price by target called. target: " + targetIdentifier)
            const header = {
                Authorization: `Bearer ${token}`
            }

            const res = await axios.post(`${ServerProperty.BASE_URL}/data/price/targets/${targetIdentifier}/collect`, null, {headers: header})

            console.log("price web adapter invoke collect price by target called. status: " + res.status)

            return
        } catch (err) {
            handleException(err)
            return
        }
    }

    async invokeCollectPricesByTargetAndUnit(targetIdentifier: String, unit: String, token: String): Promise<void> {
        try {
            console.log("price web adapter invoke collect price by target and unit called. target: " + targetIdentifier + ", unit: " + unit)
            const header = {
                Authorization: `Bearer ${token}`
            }

            const res = await axios.post(`${ServerProperty.BASE_URL}/data/price/targets/${targetIdentifier}/units/${unit}/collect`, null, {headers: header})

            console.log("price web adapter invoke collect price by target and unit called. status: " + res.status)

            return
        } catch (err) {
            handleException(err)
            return
        }
    }
}