import axios from "axios";
import ServerProperty from "../../global/property/ServerProperty";
import handleException from "../../global/exception/AxiosExceptionHandler";
import {Market, TradeTarget, TradeTargetStatus} from "../../domain/tradetarget/TradeTarget";

interface TradeTargetResponseType {
    identifier: String,
    market: String,
    name: String,
    status: String,
}

export default class TradeTargetWebAdapter {
    async collect(token: String): Promise<TradeTarget[]> {
        try {
            console.log("trade target web adapter collect called")
            const header = {
                Authorization: `Bearer ${token}`
            }

            const res = await axios.get(`${ServerProperty.BASE_URL}/data/trade-targets`, {headers: header})

            const resDatas = res.data as TradeTargetResponseType[];

            console.log("trade target web adapter collect called. size: " + resDatas.length)

            return resDatas.map((it) => {
                return new TradeTarget(
                    it.identifier,
                    it.market as Market,
                    it.name,
                    it.status as TradeTargetStatus
                )
            })
        } catch (err) {
            handleException(err)
            return []
        }
    }


    async invokeCollectTradeTargets(market: Market, token: String): Promise<void> {
        try {
            console.log("trade target web adapter invoke collect trade target called. market: " + market)
            const header = {
                Authorization: `Bearer ${token}`
            }

            const res = await axios.post(`${ServerProperty.BASE_URL}/data/targets/markets/${market}/collect`, null, {headers: header})

            console.log("trade target web adapter invoke collect trade target called. status: " + res.status)

            return
        } catch (err) {
            handleException(err)
            return
        }
    }


}